import { useRecoilState } from "recoil";

import OrderBagObj from "../Utils/OrderBagObj";
import {
  currentBagAtom,
  orderBagsAtom,
  productsAtom,
  currentOrderAtom,
  shippingMethodsAtom,
} from "../Utils/Atoms";
import { getProduct, getSubproduct } from "../Utils/Products";
import { isDoorToDoor } from "../Utils/Helpers";

function NewOrderFooter(props: any) {
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [products, setProducts] = useRecoilState(productsAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [shippingMethods, setShippingMethods] =
    useRecoilState(shippingMethodsAtom);

  const calculateLaundrySubtotal = () => {
    let subtotal = 0;
    orderBags.map((bag: any) => {
      let product = getProduct(bag.product_id, products);

      if (product?.category == "laundry") {
        if (product.variant_type == "quantity") {
          subtotal += Number(bag.product_qty) * Number(product.unit_price);
        } else if (product.variant_type == "variant") {
          let subproduct = getSubproduct(
            bag.subproduct_id,
            product.subproducts
          );
          subtotal += Number(subproduct.unit_price);
        }
      }
    });
    return subtotal;
  };

  const calculateDeliveryCharge = (subtotal1: any, subtotal2: any) => {
    if (props.finalStep && subtotal1 + subtotal2 >= 100) {
      return isDoorToDoor(currentOrder.shipping_method_id, shippingMethods)
        ? 12
        : 0;
    }
    return currentOrder.shipping_method_amount;
  };

  const calculateAddonsSubtotal = (): number => {
    let subtotal = 0;
    orderBags.map((bag: any) => {
      let product = getProduct(bag.product_id, products);

      if (product?.category == "addon") {
        if (product.variant_type == "quantity") {
          subtotal += Number(bag.product_qty) * Number(product.unit_price);
        } else if (product.variant_type == "variant") {
          let subproduct = getSubproduct(
            bag.subproduct_id,
            product.subproducts
          );
          subtotal += Number(subproduct.unit_price);
        }
      }
    });
    return subtotal;
  };

  const calculateLaundryTotal = (): number => {
    const subtotal1 = calculateLaundrySubtotal();
    const subtotal2 = calculateAddonsSubtotal();
    const subtotal3 = calculateDeliveryCharge(subtotal1, subtotal2);
    return subtotal1 + subtotal2 + subtotal3;
  };

  return (
    <div className={`x-bag-${props.item ? "item-" : ""}footer`}>
      <div className="x-btn-container">
        {props.secondaryButton ? props.secondaryButton() : null}
        <button
          onClick={props.goToNext}
          className={`${
            props.mainCustomClass ? props.mainCustomClass : "x-btn w-button"
          } ${props.disabled ? " for-secondary" : ""}`}
          disabled={props.disabled}
        >
          {props.buttonText || ""}
          {props.finalStep
            ? props.isSubmitting
              ? "Submitting..."
              : `Pay RM${calculateLaundryTotal().toFixed(2)} & Order`
            : ""}
        </button>
      </div>
      {props.hideOrderTotal ? null : (
        <div className="x-bag-total">
          <div className="x-emp-text for-med">
            {props.finalStep ? "Total payable" : "Grand total"}
          </div>
          <div className="x-brand-emp">{`RM${calculateLaundryTotal().toFixed(
            2
          )}`}</div>
        </div>
      )}
    </div>
  );
}

export default NewOrderFooter;
